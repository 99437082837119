import { useEffect, useState } from "react";
import { Container } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import AddIcon from "@mui/icons-material/Add";
import MinimizeIcon from "@mui/icons-material/Minimize";

import "./home.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import futureImg from "../../assets/image/ARTIST.png";
import directionImg from "../../assets/image/DIRECTION.png";
import gameImg from "../../assets/image/GAMIING.png";
import tshirtImg from "../../assets/image/tshirt.png";
import { ComponentHero } from "../com-hero/comHero";
import { roadmapList } from "./home.config";
import { Footer } from "../layouts/footer";
import Chart from "../Pie";
import faqImg from "../../assets/image/faq.png";
import faqList from "./faq.json";
// @ts-ignore
import Fade from "react-reveal/Fade";
// @ts-ignore
import Rotate from "react-reveal/Rotate";
// @ts-ignore
import Zoom from "react-reveal/Zoom";

export const HomePage = () => {
  const [sliderCount, setSliderCount] = useState(0);
  const [activeRoadmap, setActiveRoadmap] = useState(0);

  useEffect(() => {
    changeSliderCount();
    window.addEventListener("resize", changeSliderCount);
  }, []);

  const changeSliderCount = () => {
    let tempCount = 0;

    if (window.innerWidth > 1500) {
      tempCount = 4;
    } else if (window.innerWidth > 1024) {
      tempCount = 3;
    } else if (window.innerWidth > 600) {
      tempCount = 2;
    } else {
      tempCount = 1;
    }

    setSliderCount(tempCount);
  };

  return (
    <div className="flex flex-col bg-[#1b8ad2]" id="buy-ai-now">
      <ComponentHero />
      <CustomDivider />
    </div>
  );
};

const CustomDivider = () => {
  return <div className="" />;
};

const FapQuestion = ({ title, answer }: { title: string; answer: string }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className="flex flex-row justify-between items-center p-5 md:p-15 select-none cursor-pointer text-borderColor"
      >
        <div className="text-20 font-semibold">{title}</div>

        {open && <MinimizeIcon className="text-borderColor" />}

        {!open && <AddIcon className="text-borderColor" />}
      </div>

      {open && (
        <div className="text-15 md:text-16 px-15 md:px-20 py-5 md:py-10">
          {answer}
        </div>
      )}

      <div className="h-1 bg-borderColor opacity-50" />
    </div>
  );
};
