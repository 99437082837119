import React from 'react'
import { forwardRef } from 'react';
import { Icon } from '@iconify/react';
import { Box, BoxProps } from '@mui/material';

import { IconifyProps } from './types';

interface Props extends BoxProps {
  icon: IconifyProps;
}

// ----------------------------------------------------------------------
export const Iconify = forwardRef<SVGElement, Props>(({ icon, width = 20, sx, ...other }, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
));